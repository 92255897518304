<template>
  <div  class=" ma-5 mb-16 px-0 px-sm-16" >
    <div class="text-h6 font-weight-black my-4">个人中心</div>

      <v-card
          class="mx-auto "
        
          till flat
          
      >
          <v-list flat>
          <v-list-item-group v-model="activeItem" color="indigo " >
              <v-list-item to="/profile/myorder">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">我的订单</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item to="/profile/favorite">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">愿望单</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item to="/profile/coupon">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">我的优惠券</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item class="mt-8" to="/profile/create">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">上传推文</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item to="/profile/mytopic">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">我的推文</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item to="/profile/balance">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">我的金库</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>


              <v-list-item   class="mt-8" to="/profile/member_level">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">VIP会员</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item to="/profile/basic">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">个人信息</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item to="/profile/address">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">地址管理</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item  to="/profile/security">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">账号安全</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

              <v-list-item @click="logout">
                  <v-list-item-content>
                      <v-list-item-title class="font-weight-black">退出登录</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>








          </v-list-item-group>
          </v-list>
      </v-card>


  </div>
</template>

<script>



import userApi from '@/api/user'

export default {


  data: () => ({
    activeItem :0
    

  
    //
  }),
  
  created() {

    
    
  },
  methods: {
    logout() {
        userApi.logout().then(()=>{
            this.$store.dispatch("user/set_userinfo",{user_name:false})
            this.$router.push("/")
        })
      }
  },
};
</script>
<style scoped>

.item-header {
  background-color: black;
  color:#ffffff;
  
  width:100%;
}
</style>

